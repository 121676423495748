import axios from 'axios' 
import getEnv from './env.js';

// const apiToken = localStorage.getItem('token') || ""
// console.log(`apiToken`,apiToken)
let game_id = location.pathname.split("/")[1]

// api site domain ;
const instance = axios.create({
  baseURL: getEnv('VUE_APP_API_URL') ,
  headers: { 
    'Content-Type': 'application/json',
  },
  timeout: 10000
})

instance.interceptors.request.use(
  ( config ) => {
    const apiToken = localStorage.getItem(`${game_id}`)
    if (apiToken) {
      config.headers['Authorization'] = `Bearer ${apiToken}`;
      // console.log(`tokentokentoken`,config.headers)
    }
    return config ;
  },
  ( error ) => {
    return Promise.reject(error) ;
  }
)
// instance.interceptors.response.use(
//   ( response ) => {
//     if ( response.status === 200 ) {
//       return Promise.resolve(response) ;
//     }
//     else {
//       return Promise.reject(response) ;
//     }
//   },
//   // 根據不同的回應碼來訂製不同的錯誤訊息
//   (error) => {
//     if (error && error.response) {
//       switch (error.response.status) {
//         case 400:
//           error.message = 'Request Error!'
//           break
//         case 401:
//           error.message = 'No permission, need login.'
//           break
//         case 403:
//           error.message = 'Access denied!'
//           break
//         case 404:
//           // 自動帶入 request 地址的寫法
//           error.message = `Address not exist: ${error.response.config.url}`
//           break
//         case 408:
//           error.message = 'Request timeout!'
//           break
//         case 500:
//           error.message = 'Server inside error!'
//           break
//         case 501:
//           error.message = 'Service not allowed!'
//           break
//         case 502:
//           error.message = 'Bad gateway!'
//           break
//         case 503:
//           error.message = 'No service!'
//           break
//         case 504:
//           error.message = 'Gateway timeout!'
//           break
//         case 505:
//           error.message = 'http version not supported!'
//           break
//         default:
//           break
//       }
//     }
//     console.log('error',error)
//     return Promise.reject(error)
//   }
// )
export default function(method, url, data = null, config) {
    method = method.toLowerCase();
    switch (method) {
      case "post":
        return instance.post(url, data, config);
      case "get":
        return instance.get(url, { params: data });
      case "delete":
        return instance.delete(url, { params: data });
      case "put":
        return instance.put(url, data);
      case "patch":
        return instance.patch(url, data);
      default:
        console.log(`未知的 method: ${method}`);
        return false;
    }
  }

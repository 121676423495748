<template>
  <div class="register" :style="{ 'background-image': `url(${registerBg})`}">>
    <!-- 登入 -->
    <div v-if="hasAccount" class="register-box login">
      <div
        class="register-col"
        @click="resetInput('email')"
        :class="{ shake: notice.email }"
      >
        <label for="">email</label>
        <input
          type="email"
          v-model="login.email"
          @blur="validateInput('email', login.email)"
        />
        <span v-if="notice.email" class="validateText">{{
          emailErrorMsg
        }}</span>
      </div>
      <div class="register-col" :class="{ shake: notice.code }"  @click="resetInput('userNumber')">
        <label for="">序號</label>
        <input type="text" v-model="login.code" />
        <span v-if="notice.code" class="validateText long">{{
          codeErrorMsg
        }}</span>
      </div>
      <div class="register-col">
        <div @click="handleLogin(login)" class="btn-register">登入</div>
        <div class="btn-changeBox" @click="changeBox">尚未註冊</div>
      </div>
    </div>

    <!--註冊-->
    <div v-else class="register-box">
      <div class="register-col" :class="{ shake: notice.email }"
          @click="resetInput('email')">
          <label for="register_email">email</label>
          <input
            id="register_email"
            v-model="register.email"
            type="email"
            @input="validateInput('email', register.email)"
          />
        <span v-if="notice.email" class="validateText">{{
          emailErrorMsg
        }}</span>
      </div>
      <div class="register-col"   :class="{ shake: notice.code }"
          @click="resetInput('code')">
          <label for="register_userNumber">序號</label>
          <input type="text" id="register_userNumber" v-model="register.code" @input="validateInput('code', register.code)"/>
        <span v-if="notice.code" class="validateText">{{
          codeErrorMsg
        }}</span>
      </div>
      <div class="register-col" :class="{ shake: notice.name }"
          @click="resetInput('name')">
          <label for="register_name">暱稱</label>
          <input
            maxlength="15" 
            autocomplete="off"
            id="register_name"
            v-model="register.name"
            type="text"
            @blur="validateInput('name', register.name)"
          />
        <span v-if="notice.name" class="validateText long"
          >格式錯誤，20字元以內，限中英文</span
        >
      </div>
      <div class="register-col">
        <button @click="handleRegister(register)" class="btn-register" :disabled="inputCheck">註冊</button>
        <div class="btn-changeBox" @click="changeBox">註冊過了</div>
      </div>
    </div>
    <!-- modal....彈出 -->
    <div class="register_modal" v-if="modalOpen" @click="closeModal()">
      <div class="continue text" v-show="loginSuccess">開始遊戲</div>
      <div class="sendMail text" v-show="registerSend">
        <span v-if="registerSuccess">認證信已發送至信箱</span> 
        <span v-else>驗證中請收後</span> 
      </div>
    </div>
  </div>
</template>
<script>
import { login, register, getTokenInfo } from "@/apis/register.js";
// import { getGameData } from "@/apis/game.js";

export default {
  data() {
    return {
      hasAccount: false,
      registerBg:null,
      notice: {
        email: false,
        code: false,
        name: false,
      },
      check:{
        email: false,
        code: false,
        name: false,
      },
      register: {
        email: "",
        code: "",
        name: "",
      },
      login: {
        email: "",
        code: "",
      },
      loginSuccess: false,
      registerSuccess: false,
      registerSend:false,
      modalOpen: false,
      codeErrorMsg: "格式錯誤或是已註冊過",
      emailErrorMsg: "格式錯誤或是已註冊過",
      game_id: "",
      isNew:false,
    };
  },
  created() {
    this.getToken();
    this.game_id = this.$route.params.game_id;
    document.title = this.game_id
  },
  methods: {
    async handleRegister(data) {
      let res = await register(data);
      this.registerSend = true
      if (res.message === "註冊成功") {
        this.modalOpen = true;
        this.registerSuccess = true;
        this.login.email = this.register.email
        this.login.code = this.register.code
      }
      if (res.status === 422) {
        for (const [key, value] of Object.entries(res.data.errors)) {
          if (key === "email") {
            this.notice.email = true;
            this.emailErrorMsg = value[0];
          } else {
            this.notice.code = true;
            this.codeErrorMsg = value[0];
          }
        }
      }
    },

    async handleLogin(data) {
      let res = await login(data);
      if (res.message === "登入成功") {
        this.loginSuccess = true
        this.modalOpen = true;
        localStorage.setItem(`${this.game_id}`, res.data.token);
        this.$router.push(`/${this.game_id}/game`);
      }
      if (res.status === 422) {
        for (const [key, value] of Object.entries(res.data.errors)) {
          if (key === "email") {
            this.notice.email = true;
            this.emailErrorMsg = value[0];
          } else {
            this.notice.code = true;
            this.codeErrorMsg = value[0];
          }
        }
      }
    },
    changeBox() {
      this.hasAccount = !this.hasAccount;
    },
    validateInput(inputName, val) {
      if (inputName === "email") {
        this.validateEmail(val)? (this.notice.email = false ): (this.notice.email = true);
        this.check.email = this.validateEmail(val)
      }else if(inputName === "code"){
        val ? (this.notice.code = false):(this.notice.code = true);
        (val && !this.notice.code)? this.check.code = true : this.check.code = false
      }
      else {
        this.validateNickname(val) ? (this.notice.name = false): (this.notice.name = true);
        this.check.name = this.validateNickname(val)
      }
    },
    resetInput(inputName) {
      if (this.notice[inputName]) {
        this.notice[inputName] = false;
        this.register[inputName]= "";
        this.login[inputName]="";
      }
     
    },
    closeModal() {
      this.modalOpen = false;
      if(this.registerSuccess){
        this.registerSuccess = false;
        this.changeBox()
      }
      
      this.loginSuccess = false;
    },
    async getToken() {
      let res = await getTokenInfo();
    if(res.message){
        let statusCode = res.response.status
        if(statusCode === 500){
          this.$router.push(`/${this.game_id}/game`);
          // localStorage.removeItem(`${this.game_id}_scriptUrl`)
          this.getToken()
        }else if(statusCode ===403 ){
          this.getToken() 
        }else{
          this.$router.push(`/${this.game_id}/game`)
        }
        return
      }
      //玩到一半被重置
      if(res.data.isNew){
        // localStorage.setItem(`${this.game_id}_scriptUrl`, res.data.next);
        this.$router.push(`/${this.game_id}/game`);
      }
      //被停權
    if(!res.data.isNew && res.data.isNew!== undefined) {
        localStorage.setItem(`${this.game_id}_scriptUrl`, res.data.next);
        this.$router.push(`/${this.game_id}/game`);
    }
    //什麼都沒有的時候會進到這個
    else{
          localStorage.setItem(`${this.game_id}_scriptUrl`, `https://test-api.phonocatstudio.com/api/${this.game_id}`);
          this.registerBg = res.data.login_background_image
      }
    },
  },
  computed:{
    inputCheck(){
      if(this.check.email && this.check.code && this.check.name){
        return false
      }else{
        return true
      }
    }
  }
};
</script>
<style lang="scss">
.register {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  // background: url("../assets/background/bg_register_long.jpg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.register-box {
  width: 90%;
  border-radius: 3.3vw;
  margin-bottom: 2.8vh;
  background-color: rgba(217, 217, 217, 0.65);
  backdrop-filter: blur(12.5px) brightness(2.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register-col {
  position: relative;
  width: 100%;
  height: 7.5vh;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #808080;
  padding: 0 4.5vw;
  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 2.7vh;
    width: 80%;
  }
  label {
    font-size: 2.6vh;
    color: #000000;
    letter-spacing: 3.33px;
    // margin-left: 4.5vw;
    margin-right: 1vw;
    width: 20%;
  }
  &:nth-last-child(1) {
    flex-direction: column;
    height: 15vh;
  }
}

.validateText {
  position: absolute;
  font-size: 3.2vw;
  color: red;
  padding: 0.6vh 4.5vw;
  border-radius: 3.3vw;
  background-color: #fff;
  border: 1px solid #808080;
  white-space: nowrap;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -90%);
  &.long {
    padding: 0.6vh 2.4vw;
  }
}
.shake {
  animation: shake 0.3s ease-in-out;
  input {
    color: red;
  }
}

@keyframes shake {
  0%,
  100% {
    margin-left: 0px;
  }
  20%,
  80% {
    margin-left: -12px;
  }
  40%,
  100% {
    margin-left: 0px;
  }
}

.btn-register {
  width: 81vw;
  background-color: #fff;
  font-size: 3.4vh;
  box-shadow: 0px 6px 6px rgba(128, 128, 128, 1);
  border-radius: 2.7vw;
  padding: 0.5vh;
  margin-top: 2.8vh;
  margin-bottom: 1.9vh;
  text-align: center;
  letter-spacing: 5vw;
  text-indent: 5vw;
  cursor: pointer;
  border:none
}
.btn-changeBox {
  font-size: 1.9vh;
  letter-spacing: 1vw;
  text-indent: 1vw;
  cursor: pointer;
}
.text-error-color {
  color: red;
}

.register_modal {
  width: 100vw;
  height: 100vh;
 height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  .text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vw;
    letter-spacing: 2.4vw;
    font-weight: 400;
  }
  .continue {
    background-color: rgba(256, 256, 256, 0.85);
  }
  .sendMail {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
  }
}
</style>

import request from '@/utils/request.js';

let game_id = location.pathname.split("/")[1]
// console.log(`game_id`,game_id)

export const getTokenInfo = () =>
    request("get", `/api/${game_id}`)
        .then(function (res) {
            // console.log(`這是getTokenInfo打的res`,res)
            return res;
        })
        .catch(error => { return error });


export const login = data =>
    request("post", `/api/${game_id}/login`, data)
        .then(function (res) {
            return res.data;
        })
        .catch(error => { return error.response});


export const register = data =>
    request("post", `/api/${game_id}/register`, data)
        .then(function (res) {
            return res.data;
        })
        .catch(error => { return error.response});